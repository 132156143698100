<template>
  <div v-if="totalComments > 0 || canComment === true" class="comments-container">
    <div class="comment-actions">
      <b-btn v-if="totalComments > 0" variant="link" :disabled="commentsLoading[parentId]" @click="initComments()">
        <i class="icon-speechbobbel" /> {{ totalComments }}
        {{ 'COMMENTS_COMMENTS' | fromTextKey }}
      </b-btn>
      <b-btn
        v-if="canComment && !profile.communicationBlock"
        variant="link"
        class="ml-3"
        @click="
          initComments();
          focusInput();
        "
      >
        {{ 'COMMENTS_WRITE_COMMENT' | fromTextKey }}
      </b-btn>
    </div>
    <div v-if="showComments" class="comments">
      <div v-if="filteredComments.length < totalMainComments" class="comments-load-more" @click="loadMoreComments()">
        <i class="icon-speechbobbel" />
        {{ 'COMMENTS_SHOW_EARLIER_COMMENTS' | fromTextKey }}
      </div>
      <div v-for="(comment, i) in filteredComments" :key="i" class="comment mb-2">
        <div class="comment-creator">
          <ContactAvatar :profile="comment.creator" />
        </div>
        <div
          class="comment-data"
          :class="{
            'comment-highlight': highlightComment != null && comment.id === highlightComment.id,
          }"
        >
          <div class="comment-card">
            <strong v-if="comment.creator">{{ comment.creator | displayProfileNameWithMetadata }} </strong>
            <template v-if="comment.updatedAt != null"> {{ 'COMMENTS_COMMENT_IS_EDITED' | fromTextKey }} </template
            >{{ comment.updatedAt != null ? comment.updatedAt : comment.createdAt | shortDate(true) }}&nbsp;&nbsp; kl.
            {{
              comment.updatedAt != null
                ? moment(comment.updatedAt).format('HH:mm')
                : moment(comment.createdAt).format('HH:mm')
            }}
            <b-dropdown v-if="commentOptions[i]" class="dropdown-select" no-caret right>
              <template slot="button-content">
                <span class="icon-Aula_elipses" />
              </template>
              <b-dropdown-item
                v-if="
                  comment.creator &&
                  institutions.find(inst => inst.institutionProfileId === comment.creator.id) != null &&
                  canComment
                "
                @click="showEditComment(comment)"
              >
                <i class="icon-Aula_edit_pen" />
                {{ 'COMMENTS_COMMENT_EDIT' | fromTextKey }}
              </b-dropdown-item>
              <b-dropdown-item v-if="comment.canDelete" @click="showDeleteModal(comment)">
                <i class="icon-Aula_bin" />
                {{ 'COMMENTS_COMMENT_DELETE' | fromTextKey }}
              </b-dropdown-item>
              <b-dropdown-item v-if="comment.canReport" @click="showReportModal(comment)">
                <i class="icon-Aula_write" />
                {{ 'COMMENTS_COMMENT_REPORT' | fromTextKey }}
              </b-dropdown-item>
            </b-dropdown>
            <div v-if="commentToEdit.id === comment.id" class="add-comment">
              <b-form-textarea
                v-model="editCommentString"
                :maxlength="maxLength"
                @keydown.enter.exact="editCommentNotEmpty ? inputHandler($event, 'editComment') : ''"
              />
              <a v-if="editCommentNotEmpty" class="btn-link add-comment-btn" @click="editComment()">GEM</a>
            </div>
            <div v-else class="comment-content">
              <template v-if="comment.isDeleted">
                {{ 'COMMENTS_COMMENT_IS_DELETED' | fromTextKey }}
              </template>
              <template v-else>
                <template v-if="comment.isReported && !showReportedComments">
                  {{ 'COMMENTS_COMMENT_IS_REPORTED' | fromTextKey }}
                </template>
                <template v-else>
                  {{ comment.content }}
                </template>
              </template>
              <br />
            </div>
          </div>
          <b-btn
            v-if="!comment.isDeleted && (!comment.isReported || showReportedComments) && comment.commentCount > 0"
            variant="link"
            @click="loadSubComments(comment)"
          >
            {{ comment.commentCount }} svar
          </b-btn>
          <b-btn
            v-if="!comment.isDeleted && !comment.isReported && canComment && !profile.communicationBlock"
            variant="link"
            class="mb-2 reply-comment"
            @click="replyToSubComment(comment)"
          >
            <i class="icon-Aula_redirect" /> Svar
          </b-btn>
          <!-- Subcomments -->
          <div
            v-if="comment.comments && comment.comments.length < comment.commentCount"
            class="comments-load-more"
            @click="loadMoreSubComments(comment)"
          >
            <i class="icon-speechbobbel" />
            {{ 'COMMENTS_SHOW_EARLIER_REPLYS' | fromTextKey }}
          </div>
          <template v-for="(subComment, j) in comment.comments">
            <div
              v-if="!comment.isDeleted && (!comment.isReported || showReportedComments)"
              :key="i + '-' + j"
              class="comment mb-2"
              :class="{
                'comment-highlight': highlightComment != null && subComment.id === highlightComment.id,
              }"
            >
              <div class="comment-creator">
                <ContactAvatar :profile="comment.creator" />
              </div>
              <div class="comment-data">
                <div class="comment-card">
                  <strong v-if="subComment.creator">{{ subComment.creator | displayProfileNameWithMetadata }}</strong>
                  <template v-if="subComment.updatedAt != null">
                    {{ 'COMMENTS_COMMENT_IS_EDITED' | fromTextKey }} </template
                  >{{
                    subComment.updatedAt != null ? subComment.updatedAt : subComment.createdAt | shortDate(true)
                  }}&nbsp;&nbsp; kl.
                  {{
                    subComment.updatedAt != null
                      ? moment(subComment.updatedAt).format('HH:mm')
                      : moment(subComment.createdAt).format('HH:mm')
                  }}
                  <b-dropdown v-if="subcommentOptions[i][j]" class="dropdown-select" no-caret right>
                    <template slot="button-content">
                      <span class="icon-Aula_elipses" />
                    </template>
                    <b-dropdown-item
                      v-if="
                        subComment.creator &&
                        institutions.find(inst => inst.institutionProfileId === subComment.creator.id) != null &&
                        canComment
                      "
                      @click="showEditComment(comment, subComment)"
                    >
                      <i class="icon-Aula_edit_pen" />
                      {{ 'COMMENTS_COMMENT_EDIT' | fromTextKey }}
                    </b-dropdown-item>
                    <b-dropdown-item v-if="subComment.canDelete" @click="showDeleteModal(comment, subComment)">
                      <i class="icon-Aula_bin" />
                      {{ 'COMMENTS_COMMENT_DELETE' | fromTextKey }}
                    </b-dropdown-item>
                    <b-dropdown-item v-if="subComment.canReport" @click="showReportModal(comment, subComment)">
                      <i class="icon-Aula_write" />
                      {{ 'COMMENTS_COMMENT_REPORT' | fromTextKey }}
                    </b-dropdown-item>
                  </b-dropdown>
                  <div v-if="commentToEdit.id === subComment.id" class="add-comment">
                    <b-form-textarea
                      v-model="editCommentString"
                      :maxlength="maxLength"
                      @keydown.enter.exact="editCommentNotEmpty ? inputHandler($event, 'editSubComment') : ''"
                    />
                    <a v-if="editCommentNotEmpty" class="btn-link add-comment-btn" @click="editSubComment()">GEM</a>
                  </div>
                  <div v-else class="comment-content">
                    <template v-if="subComment.isDeleted">
                      {{ 'COMMENTS_COMMENT_IS_DELETED' | fromTextKey }}
                    </template>
                    <template v-else>
                      <template v-if="subComment.isReported && !showReportedComments">
                        {{ 'COMMENTS_COMMENT_IS_REPORTED' | fromTextKey }}
                      </template>
                      <template v-else>
                        {{ subComment.content }}
                      </template>
                    </template>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </template>
          <div
            v-if="showReplyToComment.id === comment.id && canComment && !profile.communicationBlock"
            class="add-comment"
          >
            <template v-if="commentableProfiles && commentableProfiles.length > 1">
              {{ 'COMMENTS_CHOOSE_INSTITUTION' | fromTextKey }}<br />
              <aula-select
                v-model="creatorInstProfileId"
                :placeholder="'SELECT' | fromTextKey"
                :aria-label="'COMMENTS_CHOOSE_INSTITUTION' | fromTextKey"
              >
                <aula-option
                  v-for="instProfile in commentableProfiles"
                  :id="instProfile.id"
                  :key="instProfile.id"
                  :label="instProfile.institutionName"
                  :value="instProfile.id"
                />
              </aula-select>
              <br /><br />
            </template>
            <b-form-textarea
              ref="subCommentInput"
              v-model="subCommentString"
              :placeholder="'COMMENTS_WRITE_COMMENT' | fromTextKey"
              :maxlength="maxLength"
              @keydown.enter.exact="subcommentNotEmpty ? inputHandler($event, 'saveSubComment') : ''"
            />
            <a v-if="subcommentNotEmpty" class="btn-link add-comment-btn" @click="saveSubComment()">SVAR</a>
          </div>
        </div>
      </div>
      <div
        v-if="canComment && !showReportedComments && !profile.communicationBlock && profile.role != portalRoles.OTP"
        class="add-comment"
      >
        <template v-if="commentableProfiles && commentableProfiles.length > 1">
          {{ 'COMMENTS_CHOOSE_INSTITUTION' | fromTextKey }}<br />
          <aula-select
            v-model="creatorInstProfileId"
            :placeholder="'SELECT' | fromTextKey"
            :aria-label="'COMMENTS_CHOOSE_INSTITUTION' | fromTextKey"
          >
            <aula-option
              v-for="instProfile in commentableProfiles"
              :id="instProfile.id"
              :key="instProfile.id"
              :label="instProfile.institutionName"
              :value="instProfile.id"
            />
          </aula-select>
          <br /><br />
        </template>
        <b-form-textarea
          ref="commentInput"
          v-model="commentString"
          :placeholder="'COMMENTS_WRITE_COMMENT' | fromTextKey"
          :maxlength="maxLength"
          @keydown.enter.exact="commentNotEmpty ? inputHandler($event, 'saveComment') : ''"
        />
        <a v-if="commentNotEmpty" class="btn-link add-comment-btn" @click="saveComment()">{{
          'MESSAGE_RESPOND' | fromTextKey
        }}</a>
      </div>
    </div>
    <aula-modal
      ref="reportContentModal"
      ok-text="REPORTED_CONTENT_SEND"
      header-text="REPORTED_CONTENT_HEADER"
      @okClicked="confirmReportComment()"
      @cancelClicked="
        $refs.reportContentModal.hide();
        $refs.cancelReportModal.show();
      "
    >
      {{ 'REPORTED_CONTENT_WRITE_A_REASON' | fromTextKey }}
      <b-form-textarea
        v-model="reportedContentReason"
        :placeholder="'REPORTED_CONTENT_FIELDS_REASON' | fromTextKey"
        @input="showWarningReasonLength = false"
      />
      <b-alert variant="danger" :show="showWarningReasonLength">
        {{ warningReasonLength | fromTextKey }}
      </b-alert>
      {{ 'REPORTED_CONTENT_NOTE' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="cancelReportModal"
      @okClicked="$refs.cancelReportModal.hide()"
      @cancelClicked="
        $refs.cancelReportModal.hide();
        $refs.reportContentModal.show();
      "
    >
      {{ 'REPORTED_CONTENT_CANCEL_REPORT_1' | fromTextKey }}<br />
      {{ 'REPORTED_CONTENT_CANCEL_REPORT_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="deleteModal"
      ok-text="YES"
      @okClicked="confirmDeleteComment()"
      @cancelClicked="$refs.deleteModal.hide()"
    >
      {{ 'COMMENTS_CONFIRM_DELETE' | fromTextKey }}<br />
      {{ 'COMMENTS_CONFIRM_DELETE_2' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { types } from '../../src/store/types/types';
import { commentTypes } from '../enums/commentTypes';
import { permissionEnum } from '../../shared/enums/permissionEnum.ts';
import moment from 'moment-timezone';
import { portalRoles } from '../enums/portalRoles';
import { getDefaultInstitutionCode } from '../utils/institutionUtil';
import debounce from 'lodash/debounce';
import ContactAvatar from '../../src/components/contacts/ContactAvatar.vue';

export default {
  props: {
    parentId: { type: [Number, String], default: null },
    parentType: { type: String, default: commentTypes.POST },
    clearAll: { type: Boolean, default: false },
    showReportedComments: { type: Boolean, default: false },
    commentCount: { type: Number, default: 0 },
    highlightComment: { type: Object, default: () => {} },
    canComment: { type: Boolean, default: true },
  },
  data() {
    return {
      showComments: false,
      commentsAreLoaded: false,
      subCommentsAreLoaded: [],
      showReplyToComment: {},
      commentToEdit: {},
      selectedComment: null,
      selectedSubComment: null,
      commentString: '',
      subCommentString: '',
      editCommentString: '',
      nextRoute: '',
      reportedContentReason: '',
      showWarningReasonLength: false,
      warningReasonLength: null,
      moment: moment,
      totalComments: 0,
      maxLength: 10000,
      creatorInstProfileId: null,
      isPostingComment: false,
      portalRoles,
      commentsLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      comments: types.GET_COMMENTS,
      commentsCount: types.GET_COMMENTS_COUNT,
      profile: types.GET_CURRENT_PROFILE,
      hasPermission: types.HAS_PERMISSION,
      institutions: types.GET_INSTITUTIONS,
      activeInstitutionCodes: types.GET_ACTIVE_INSTITUTIONS,
      userGroups: types.GET_USER_GROUPS,
      commentableInstitutionProfiles: types.GET_COMMENTABLE_INSTITUTION_PROFILES,
    }),
    isInstitutionalAdmin() {
      return this.hasPermission(permissionEnum.ADMIN_MODULE);
    },
    parentTypeLowCase() {
      return this.parentType.toLowerCase();
    },
    totalMainComments() {
      if (this.commentsCount[this.parentTypeLowCase][this.parentId] != null) {
        return this.commentsCount[this.parentTypeLowCase][this.parentId];
      }
      return 0;
    },
    filteredComments() {
      if (this.comments[this.parentTypeLowCase][this.parentId] != null) {
        const sortedComments = [...this.comments[this.parentTypeLowCase][this.parentId]];
        return sortedComments.sort((a, b) =>
          a.createdAt.localeCompare(b.createdAt, 'da', {
            sensitivity: 'base',
          })
        );
      }
      return [];
    },
    commentableProfiles() {
      return this.commentableInstitutionProfiles[this.parentTypeLowCase][this.parentId];
    },
    commentOptions() {
      return this.filteredComments.map(comment => this.showCommentOptions(comment));
    },
    subcommentOptions() {
      return this.filteredComments.map(comment =>
        comment.comments ? comment.comments.map(subComment => this.showCommentOptions(subComment)) : false
      );
    },
    commentNotEmpty() {
      return !!this.commentString.trim();
    },
    editCommentNotEmpty() {
      return !!this.editCommentString.trim();
    },
    subcommentNotEmpty() {
      return !!this.subCommentString.trim();
    },
  },
  methods: {
    ...mapActions({
      loadComments: types.LOAD_COMMENTS,
      createComment: types.CREATE_COMMENT,
      updateComment: types.UPDATE_COMMENT,
      deleteComment: types.DELETE_COMMENT,
      reportComment: types.REPORT_COMMENT,
    }),
    ...mapMutations({
      clearComments: types.MUTATE_CLEAR_COMMENTS,
      updateWritingComment: types.MUTATE_WRITING_COMMENT,
    }),
    showCommentOptions(comment) {
      return (
        !comment.isDeleted &&
        !comment.isReported &&
        ((comment.creator && this.institutions.find(inst => inst.institutionProfileId == comment.creator.id) != null) ||
          comment.canDelete ||
          comment.canReport) &&
        !this.showReportedComments
      );
    },
    inputHandler(e, method) {
      e.preventDefault();
      this[method]();
    },
    isCreator(comment) {
      return this.institutions.find(inst => inst.institutionProfileId == comment.creator.id) != null;
    },
    showEditComment(comment, subComment = null) {
      if (subComment) {
        this.commentToEdit = { id: subComment.id };
        this.editCommentString = subComment.content;
        this.selectedSubComment = subComment;
        this.selectedComment = comment;
      } else {
        this.commentToEdit = { id: comment.id };
        this.editCommentString = comment.content;
        this.selectedComment = comment;
      }
    },
    replyToSubComment(comment) {
      if (this.showReplyToComment.id == comment.id) {
        this.showReplyToComment = {};
        this.selectedComment = null;
      } else {
        this.showReplyToComment = { id: comment.id };
        this.selectedComment = comment;
      }
    },
    showDeleteModal(comment, subComment = null) {
      this.selectedComment = comment;
      this.selectedSubComment = subComment;
      this.$refs.deleteModal.show();
    },
    confirmDeleteComment() {
      this.$refs.deleteModal.hide();
      if (this.selectedSubComment) {
        this.deleteComment({
          commentId: this.selectedSubComment.id,
          data: {
            parentObjectId: this.parentId,
            parentObjectType: this.parentType,
            parentCommentId: this.selectedComment.id,
            subCommentId: this.selectedSubComment.id,
          },
        });
      } else {
        if (this.showReplyToComment.id == this.selectedComment.id) {
          this.showReplyToComment = {};
        }
        this.deleteComment({
          commentId: this.selectedComment.id,
          data: {
            parentObjectId: this.parentId,
            parentObjectType: this.parentType,
            parentCommentId: this.selectedComment.id,
          },
        });
      }
    },
    resetCommentForm() {
      this.showWarningReasonLength = false;
      this.warningReasonLength = null;
    },
    showReportModal(comment, subComment = null) {
      this.selectedComment = comment;
      this.selectedSubComment = subComment;
      this.resetCommentForm();
      this.$refs.reportContentModal.show();
    },
    confirmReportComment() {
      if (this.reportedContentReason.length < 10 || this.reportedContentReason.length > 255) {
        this.showWarningReasonLength = true;
        this.warningReasonLength =
          this.reportedContentReason.length < 10
            ? 'REPORTED_CONTENT_REASON_LENGTH_WARNING_TOO_SHORT'
            : 'REPORTED_CONTENT_REASON_LENGTH_WARNING_TOO_LONG';
      } else {
        this.resetCommentForm();
        if (this.selectedSubComment) {
          this.reportComment({
            commentId: this.selectedSubComment.id,
            reportReason: this.reportedContentReason,
            data: {
              parentObjectId: this.parentId,
              parentObjectType: this.parentType,
              parentCommentId: this.selectedComment.id,
              subCommentId: this.selectedSubComment.id,
              report: true,
            },
          }).then(() => {
            this.$refs.reportContentModal.hide();
            this.reportedContentReason = '';
          });
        } else {
          this.reportComment({
            commentId: this.selectedComment.id,
            reportReason: this.reportedContentReason,
            data: {
              parentObjectId: this.parentId,
              parentObjectType: this.parentType,
              parentCommentId: this.selectedComment.id,
              report: true,
            },
          }).then(() => {
            this.$refs.reportContentModal.hide();
            this.reportedContentReason = '';
          });
        }
      }
    },
    initComments() {
      if (this.commentsLoading) {
        return;
      }
      if (!this.commentsAreLoaded) {
        this.commentsLoading = true;
        this.loadComments({
          parentId: this.parentId,
          parentType: this.parentType,
          startIndex: 0,
          limit: 5,
          includeReportedComments: this.showReportedComments,
          data: {
            parentObjectId: this.parentId,
            parentObjectType: this.parentType,
          },
        })
          .then(() => {
            this.showComments = true;
            this.commentsAreLoaded = true;
          })
          .finally(() => {
            this.commentsLoading = false;
          });
      } else {
        this.showComments = !this.showComments;
      }
    },
    loadSubComments(comment) {
      if (!this.subCommentsAreLoaded[comment.id]) {
        this.loadComments({
          parentId: comment.id,
          parentType: commentTypes.COMMENT,
          startIndex: 0,
          limit: 5,
          includeReportedComments: this.showReportedComments,
          data: {
            parentObjectId: this.parentId,
            parentObjectType: this.parentType,
            parentCommentId: comment.id,
          },
        }).then(() => {
          this.subCommentsAreLoaded[comment.id] = true;
        });
      }
    },
    loadMoreSubComments(comment) {
      this.loadComments({
        parentId: comment.id,
        parentType: commentTypes.COMMENT,
        startIndex: comment.comments.length,
        limit: 5,
        includeReportedComments: this.showReportedComments,
        data: {
          parentObjectId: this.parentId,
          parentObjectType: this.parentType,
          parentCommentId: comment.id,
        },
      });
    },
    loadMoreComments() {
      if (this.commentsLoading) {
        return;
      }
      this.commentsLoading = true;
      this.loadComments({
        parentId: this.parentId,
        parentType: this.parentType,
        startIndex: this.filteredComments.length,
        limit: 5,
        includeReportedComments: this.showReportedComments,
        data: {
          parentObjectId: this.parentId,
          parentObjectType: this.parentType,
        },
      }).finally(() => {
        this.commentsLoading = false;
      });
    },
    saveComment() {
      if (!this.isPostingComment) {
        this.isPostingComment = true;
        this.createComment({
          commentableItem: { id: this.parentId, type: this.parentType },
          content: this.commentString,
          creatorInstProfileId: this.creatorInstProfileId,
          creator: {
            id: this.profile.id,
            name: this.profile.firstName + ' ' + this.profile.lastName,
            shortName: this.profile.shortName,
            profilePicture: this.profile.profilePicture,
            metadata: this.profile.metadata,
          },
          createdAt: moment().format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
          comments: [],
          data: {
            parentObjectId: this.parentId,
            parentObjectType: this.parentType,
          },
        }).then(() => {
          this.commentString = '';
          this.totalComments++;
          this.$emit('commentAdded');
          this.isPostingComment = false;
        });
      }
    },
    saveSubComment() {
      this.createComment({
        commentableItem: {
          id: this.selectedComment.id,
          type: commentTypes.COMMENT,
        },
        content: this.subCommentString,
        creatorInstProfileId: this.creatorInstProfileId,
        creator: {
          id: this.profile.id,
          name: this.profile.firstName + ' ' + this.profile.lastName,
          shortName: this.profile.shortName,
          profilePicture: this.profile.profilePicture,
          metadata: this.profile.metadata,
        },
        createdAt: moment().format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
        data: {
          parentObjectId: this.parentId,
          parentObjectType: this.parentType,
          parentCommentId: this.selectedComment.id,
        },
      }).then(() => {
        this.subCommentString = '';
        this.totalComments++;
        if (this.parentType == commentTypes.MEDIA) {
          this.$emit('commentAdded');
        }
      });
    },
    editComment() {
      this.updateComment({
        commentId: this.selectedComment.id,
        content: this.editCommentString,
        data: {
          parentObjectId: this.parentId,
          parentObjectType: this.parentType,
          parentCommentId: this.selectedComment.id,
          content: this.editCommentString,
        },
      }).then(() => {
        this.commentToEdit = {};
        this.editCommentString = '';
      });
    },
    editSubComment() {
      this.updateComment({
        commentId: this.selectedSubComment.id,
        content: this.editCommentString,
        data: {
          parentObjectId: this.parentId,
          parentObjectType: this.parentType,
          parentCommentId: this.selectedComment.id,
          subCommentId: this.selectedSubComment.id,
          content: this.editCommentString,
        },
      }).then(() => {
        this.commentToEdit = {};
        this.editCommentString = '';
      });
    },
    focusInput() {
      this.showComments = true;
      this.$nextTick(() => {
        this.$refs.commentInput.focus();
      });
    },
    focusSubInput() {
      this.$nextTick(() => {
        this.$refs.subCommentInput.focus();
      });
    },
  },
  watch: {
    clearAll() {
      this.commentString = '';
      this.subCommentString = '';
      this.editCommentString = '';
    },
    commentString: debounce(function (newVal) {
      this.updateWritingComment(!!newVal);
    }, 300),
    subCommentString: debounce(function (newVal) {
      this.updateWritingComment(!!newVal);
    }, 300),
    commentCount() {
      this.totalComments = this.commentCount;
    },
    parentId() {
      this.clearComments();
      this.commentsAreLoaded = false;
      if (this.parentType == commentTypes.MEDIA && this.isMobile) {
        this.initComments();
      }
    },
    commentableProfiles() {
      if (Array.isArray(this.commentableProfiles) && this.commentableProfiles.length > 0) {
        const defaultInstitutionCode = getDefaultInstitutionCode(this.institutions, this.activeInstitutionCodes);
        const creatorInstProfile = this.commentableProfiles.find(
          profile => profile.institutionCode === defaultInstitutionCode
        );
        if (creatorInstProfile) {
          this.creatorInstProfileId = creatorInstProfile.id;
        } else {
          this.creatorInstProfileId = this.commentableProfiles[0].id;
        }
      }
    },
  },
  mounted() {
    if (this.parentType == commentTypes.MEDIA && this.isMobile) {
      this.initComments();
    }
    this.totalComments = this.commentCount;
  },
  beforeDestroy() {
    this.clearComments();
  },
  components: {
    ContactAvatar,
  },
};
</script>

<style scoped lang="scss">
@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints.scss';
.comment-highlight .comment-card {
  background-color: var(--color-grey-base);
  padding: 10px;
}
.comments-container {
  border-top: 1px solid $color-grey-darker;
  border-bottom: 1px solid $color-grey-darker;
  padding-top: 10px;
  padding-bottom: 10px;
  textarea.form-control {
    padding-right: 55px;
    height: 50px;
    .media-detail-modal & {
      background: $color-grey-lighter;
    }
  }
  .comment-actions {
    .btn-link {
      font-weight: bold;
    }
  }
  .comments {
    margin-top: 10px;
  }
  .reply-comment {
    margin-left: 10px;
    .theme-employee & {
      color: $color-primary-dark-employee;
    }
    .theme-guardian & {
      color: $color-primary-dark-guardian;
    }
    .theme-child & {
      color: $color-primary-dark-child;
    }
    .theme-admin & {
      color: $color-primary-dark-admin;
    }
  }
  .comments-load-more {
    background: $color-grey-base;
    padding: 15px;
    cursor: pointer;
    margin-bottom: 20px;
    .theme-employee & {
      color: $color-primary-dark-employee;
    }
    .theme-guardian & {
      color: $color-primary-dark-guardian;
    }
    .theme-child & {
      color: $color-primary-dark-child;
    }
    .theme-admin & {
      color: $color-primary-dark-admin;
    }
  }
  .comment {
    display: flex;
    gap: 0.5rem;
    .comment-data {
      flex: 1;
      padding-top: 8px;
      font-size: 13px;
    }
    .comment-content {
      font-size: 15px;
    }
  }
  .add-comment {
    position: relative;
    .add-comment-btn {
      position: absolute;
      right: 20px;
      bottom: 14px;
      z-index: 1000;
    }
  }

  /deep/ .user-icon {
    width: 40px !important;
    height: 40px;
    span {
      width: 40px;
      height: 40px;
    }
  }
}
</style>
