interface IsEqualOptionsModel {
  caseInsensitive?: boolean;
}

class TextUtil {
  getGenitiveName(name: string) {
    if (typeof name !== 'string' || name.length < 1) {
      return name;
    }
    let suffix = 's';
    const lastCharacter = name.charAt(name.length - 1).toLowerCase();

    if (lastCharacter === 's' || lastCharacter === 'z') {
      suffix = "'";
    }

    return name + suffix;
  }

  isEqual(a: string, b: string, options: IsEqualOptionsModel = {}) {
    let sensitivity = 'case';
    if (options.caseInsensitive === true) {
      sensitivity = 'accent';
    }

    return a.localeCompare(b, undefined, { sensitivity }) === 0;
  }

  splitAndAppend(input: string | string[], delimiter: string = ' ') {
    let result: string[] = [];
    if (typeof input === 'string') {
      result = input.split(delimiter);
    } else {
      for (const text of input) {
        const textSplit = text.split(delimiter);
        result.push(...textSplit);
      }
    }

    return result.filter(Boolean);
  }

  isPhoneNumber(value) {
    if (!value) {
      return true;
    }
    return /^[0-9\-\(\)\/\+\s]{8,15}$/.test(value);
  }

  isEmail(value) {
    if (!value) {
      return true;
    }
    return /^\S+@\S+\.\S+$/.test(value);
  }
}

export const textUtil = new TextUtil();
