




















import { PropType } from 'vue';
import { InstitutionProfileModel } from '../models/institutionProfile.model';
import AulaImg from './AulaImg.vue';
import Icon from './Icon.vue';
import { iconClassEnum } from '../enums/iconClassEnum';

export default {
  name: 'Avatar',
  components: { Icon, AulaImg },
  props: {
    profile: { type: Object as PropType<InstitutionProfileModel>, default: null },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      hasProfileImage: false,
    };
  },
  computed: {
    title() {
      if (!this.profile) {
        return '';
      }
      return this.profile.fullName || this.profile.name;
    },
    iconClassEnum() {
      return iconClassEnum;
    },
    imageUrl() {
      return this.profile?.profilePicture?.url || '';
    },
    shortNameSize() {
      if (this.profile?.shortName.length > 3) {
        return 'small';
      }
      if (this.profile?.shortName.length < 3) {
        return 'large';
      }
      return '';
    },
  },
  watch: {
    imageUrl: {
      immediate: true,
      handler() {
        this.hasProfileImage = this.profile?.profilePicture != null;
      },
    },
  },
  methods: {
    onImageError() {
      this.hasProfileImage = false;
    },
  },
};
