<template>
  <div class="media-detail-container">
    <aula-modal
      ref="mediaDetailModal"
      :always-visible="visible"
      :hide-header="true"
      :hide-footer="true"
      :visible="true"
      :disable-close="isMobile"
      :css-class="fullscreenMode ? 'media-detail-modal modal-fullscreen' : 'media-detail-modal'"
      @closeClicked="closeMediaDetail()"
    >
      <div v-if="activeMedia.file != null" class="print-only media-print">
        <AulaImg :img-url="activeMedia.file.url" />
        <br />
        <h2>{{ activeMedia.title }}</h2>
        {{ activeMedia.creator.name }} ({{ activeMedia.file.created | longDate(true) }})
        <p>{{ activeMedia.description }}</p>
        <p v-if="filteredTags.length > 0">
          {{ 'GALLERY_TAGS' | fromTextKey }}:
          <span v-for="(tag, i) in filteredTags" :key="'pt' + i" class="print_tag">
            {{ tag | displayProfileNameWithMetadata }}
          </span>
        </p>
        <p
          v-if="activeAlbum != null && activeAlbum.sharedWithGroups != null && activeAlbum.sharedWithGroups.length > 0"
        >
          {{ 'GALLERY_SHARED_WITH' | fromTextKey }}
          <template v-for="(group, i) in activeAlbum.sharedWithGroups">
            <span v-if="i < 1" :key="'pg' + i" class="print_tag">
              {{ group.name }}
              <template v-if="group.portalRoles != null"
                >(<template v-for="(role, j) in group.portalRoles"
                  >{{ ('GROUP_LABEL_TYPE_' + role.toUpperCase()) | fromTextKey
                  }}<template v-if="j < group.portalRoles.length - 1"> - </template></template
                >)</template
              >
            </span>
          </template>
        </p>
      </div>
      <div class="no-print">
        <mobile-topbar
          v-if="isMobile"
          :text="activeMedia.title"
          @leftIconClicked="
            $refs.mediaDetailModal.hide();
            closeMediaDetail();
          "
        />
        <b-row class="m-0">
          <b-col :lg="fullscreenMode ? '12' : '8'" md="12" sm="12" xl="9" class="p-0">
            <div v-if="isMobile && !showMediaDetails" class="open-media-detail" @click="showMediaDetails = true">
              {{ 'GALLERY_SHOW_INFO' | fromTextKey }}
            </div>
            <template v-if="slide !== -1">
              <b-carousel
                :value="slide"
                class="media-carousel media-detail"
                :class="{ fromSearch: activeMedia.fromSearch }"
                :controls="!isMobile"
                :interval="0"
                @sliding-start="updateSlide"
              >
                <b-carousel-slide v-for="media in mediaList" :key="media.id">
                  <AulaImg
                    v-if="media.mediaType === mediaTypes.IMAGE"
                    slot="img"
                    class="img"
                    :img-url="media.file.url"
                  />
                  <video
                    v-if="media.mediaType === mediaTypes.VIDEO"
                    :id="`video${media.id}`"
                    slot="img"
                    class="video"
                    controls
                  />
                  <audio
                    v-if="media.mediaType == mediaTypes.SOUND"
                    slot="img"
                    class="audio"
                    :src="media.file.url"
                    controls
                  />
                  <template v-for="(tag, j) in filteredTags">
                    <Avatar
                      v-if="children && children.find(child => child.id == tag.id) != null"
                      :key="j"
                      :profile="tag"
                    />
                  </template>
                </b-carousel-slide>
              </b-carousel>
            </template>
            <div v-if="!fullscreenMode && !activeMedia.fromSearch" class="thumbnails-container">
              <div
                v-if="!isMobile"
                class="media-thumbnails-prev"
                :aria-label="'ARIA_LABEL_PREVIOUS_MEDIA' | fromTextKey"
                tabindex="0"
                role="button"
                @click="showPrevThumbnails"
                @keydown.enter="showPrevThumbnails"
              >
                <i class="icon-Aula_back-arrow" />
              </div>
              <div ref="mediaThumbnails" class="media-thumbnails">
                <div
                  v-for="(media, i) in albumMedia"
                  v-show="i >= thumbnailStartIndex && i <= thumbnailEndIndex"
                  :key="media.id"
                  v-button
                  class="small-thumbnail"
                  tabindex="0"
                  :aria-selected="media.id === activeMedia.id"
                  @click="slide = i"
                  @keydown.enter="slide = i"
                >
                  <VideoThumbnail v-if="media.mediaType === mediaTypes.VIDEO" :media="media" />
                  <SoundThumbnail v-if="media.mediaType === mediaTypes.SOUND" />
                  <AulaImg
                    v-if="media.mediaType === mediaTypes.IMAGE"
                    :img-url="media.extraSmallThumbnailUrl"
                    :alt="media.title"
                  />
                </div>
              </div>
              <div
                v-if="!isMobile"
                class="media-thumbnails-next"
                :aria-label="'ARIA_LABEL_NEXT_MEDIA' | fromTextKey"
                tabindex="0"
                role="button"
                @click="showNextThumbnails"
                @keydown.enter="showNextThumbnails"
              >
                <i class="icon-Aula_forward-arrow" />
              </div>
            </div>
          </b-col>
          <b-col
            v-if="
              activeMedia != null &&
              activeMedia.id != null &&
              (!isMobile || (isMobile && showMediaDetails)) &&
              !fullscreenMode
            "
            lg="4"
            xl="3"
            sm="12"
            md="12"
            class="p-0"
            :class="{ 'information-overlay': isMobile }"
          >
            <div class="media-details">
              <div class="media-actions">
                <b-dropdown class="dropdown-select foldermenu" variant="link" :right="isMobile ? true : false">
                  <template slot="button-content">
                    <i class="icon-Aula_elipses" :aria-label="'ARIA_LABEL_GALLERY_MEDIA_OPTIONS' | fromTextKey" />
                  </template>
                  <b-dropdown-item
                    v-if="canDeleteMedia"
                    @click="
                      visible = false;
                      $refs.mediaDetailModal.hide();
                      $refs.deleteMediaModal.show();
                    "
                  >
                    {{ 'GALLERY_DELETE_MEDIA' | fromTextKey }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="downloadMedia">
                    {{ 'GALLERY_DOWNLOAD_MEDIA' | fromTextKey }}
                  </b-dropdown-item>
                  <b-dropdown-item v-if="activeMedia.currentUserCanReport" @click="openReportMediaModal()">
                    {{ 'REPORTED_CONTENT_REPORT' | fromTextKey }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="media-creator">
                <ContactAvatar :profile="activeMedia.creator" />
                <div class="media-creator-infor">
                  <div class="media-creator-name">
                    {{ activeMedia.creator | displayProfileNameWithMetadata }}
                  </div>
                  <div class="media-date">{{ activeMedia.file.created | longDate(true) }}</div>
                </div>
                <b-btn
                  v-if="isMobile"
                  tabindex="0"
                  class="ml-auto p-1"
                  variant="link"
                  :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
                  @click="showMediaDetails = false"
                  @keydown.enter="showMediaDetails = false"
                >
                  <i class="icon icon-Aula_close" />
                  <span class="sr-only">{{ 'ARIA_LABEL_DELETE' | fromTextKey }}</span>
                </b-btn>
              </div>
              <div class="media-info mt-2">
                <div
                  v-if="
                    activeMedia.conversionStatus === conversionStatus.FAILED &&
                    activeMedia.mediaType === mediaTypes.VIDEO
                  "
                  class="failed-conversion-container"
                >
                  <help-box
                    id="secure-file-share-with-tooltip"
                    :title="$options.filters.fromTextKey('GALLERY_CONVERSION_FAILED_TITLE')"
                    :body="$options.filters.fromTextKey('GALLERY_CONVERSION_FAILED_TEXT')"
                    :is-html-content="true"
                  />
                  {{ 'GALLERY_CONVERSION_FAILED_TITLE' | fromTextKey }}
                </div>
                <h2>{{ activeMedia.title }}</h2>
                {{ activeMedia.description }}
                <template v-if="parent !== parentTypes.NOTICE_BOARDS">
                  <div v-if="!profile.communicationBlock" class="media-tags" :class="!isMobile ? 'mt-4' : ''">
                    <div class="media-tag-recipients pl-2">
                      {{ 'GALLERY_TAGS' | fromTextKey }}:
                      <template v-if="filteredTags.length > 0">
                        {{ filteredTags[0] | displayProfileNameWithMetadata }}
                      </template>
                    </div>
                    <b-dropdown
                      v-if="!isMobile && showTagsDropdown()"
                      ref="mediaTagHandling"
                      class="dropdown-select foldermenu tag-panel"
                      right
                      variant="link"
                    >
                      <template slot="button-content">
                        {{ tagsDetail }} <i class="icon-Aula_down-arrow pl-2" />
                      </template>
                      <MediaTagHandling
                        :is-mobile="isMobile"
                        :existing-tags="filteredTags"
                        :can-edit-tags="canEditTags"
                        :disable-search="disableTagSearch"
                        :institution-code="albumInstitutionCode"
                        @cancelTagging="cancelTagging"
                        @addTagsToMedia="addTagsToMedia"
                        @removeTagFromMedia="removeTagFromMedia"
                      />
                    </b-dropdown>
                    <span v-if="isMobile" tabindex="0" role="button" @click="showTagDetailsMobile = true"
                      >{{ tagsDetail }}
                      <i class="icon-Aula_edit_pen" :aria-label="'ARIA_LABEL_EDIT' | fromTextKey" /></span
                    ><br />
                    <b-btn v-if="isMobile && canEditTags" variant="link" @click="showTagDetailsMobile = true">
                      {{ 'GALLERY_EDIT_TAGS' | fromTextKey }}
                      <i
                        class="icon-Aula_forward-arrow ml-2"
                        style="font-size: 10px"
                        :aria-label="'ARIA_LABEL_EDIT' | fromTextKey"
                      /> </b-btn
                    ><br />
                    <b-btn
                      v-if="
                        isMobile &&
                        (parent == parentTypes.GALLERY ||
                          parent == parentTypes.GROUP_GALLERY ||
                          parent == parentTypes.SEARCH)
                      "
                      variant="link"
                      @click="showCommentDetailsMobile = true"
                    >
                      {{ activeMedia.commentCount }}
                      {{ 'COMMENTS_COMMENTS' | fromTextKey }}
                      <i class="icon-Aula_forward-arrow ml-2" style="font-size: 10px" />
                    </b-btn>
                  </div>

                  <div
                    v-if="
                      activeAlbum != null &&
                      activeAlbum.sharedWithGroups != null &&
                      activeAlbum.sharedWithGroups.length > 0 &&
                      (parent == parentTypes.GALLERY || parent == parentTypes.GROUP || activeMedia.fromSearch)
                    "
                    class="media-shared-with"
                    :class="!isMobile ? 'mt-4' : ''"
                  >
                    {{ 'GALLERY_SHARED_WITH' | fromTextKey }}
                    <template v-for="(group, i) in activeAlbum.sharedWithGroups">
                      <template v-if="i < 1">
                        {{ group.name }}
                        <template v-if="group.portalRoles != null">
                          (<template v-for="(role, i) in group.portalRoles">
                            {{ ('GROUP_LABEL_TYPE_' + role.toUpperCase()) | fromTextKey
                            }}<template v-if="i < group.portalRoles.length - 1"> - </template> </template
                          >)
                        </template>
                      </template>
                    </template>

                    <b-dropdown
                      v-if="activeAlbum.sharedWithGroups.length > 1"
                      ref="sharedWithDropdown"
                      class="dropdown-select foldermenu"
                      right
                      variant="link"
                    >
                      <template slot="button-content">
                        {{ '+ ' + (activeAlbum.sharedWithGroups.length - 1) + ' andre' }}
                        <i class="icon-Aula_down-arrow" />
                      </template>
                      <template v-for="(group, i) in activeAlbum.sharedWithGroups">
                        <div v-if="i > 0" :key="i">
                          {{ group.name }}
                          <template v-if="group.portalRoles != null">
                            (<template v-for="(role, i) in group.portalRoles">
                              {{ ('GROUP_LABEL_TYPE_' + role.toUpperCase()) | fromTextKey
                              }}<template v-if="i < group.portalRoles.length - 1"> - </template> </template
                            >)
                          </template>
                        </div>
                      </template>
                    </b-dropdown>
                  </div>
                  <comments
                    v-if="
                      activeMedia.canViewComments &&
                      !isMobile &&
                      (parent == parentTypes.GALLERY ||
                        parent == parentTypes.GROUP_GALLERY ||
                        parent == parentTypes.SEARCH)
                    "
                    :can-comment="activeMedia.allowsComments"
                    :parent-id="activeMedia.id"
                    :parent-type="commentTypes.MEDIA"
                    :comment-count="activeMedia.commentCount"
                    :clear-all="clearAll"
                    @commentAdded="updateMediaComment"
                  />
                </template>
              </div>
            </div>
          </b-col>
        </b-row>
        <transition v-if="isMobile" name="slide-only">
          <div v-if="showTagDetailsMobile" class="media-tag-details-mobile">
            <MediaTagHandling
              :is-mobile="isMobile"
              :existing-tags="filteredTags"
              :can-edit-tags="canEditTags"
              :disable-search="disableTagSearch"
              :institution-code="albumInstitutionCode"
              @cancelTagging="hideTagDetailsMobile"
              @addTagsToMedia="addTagsToMedia"
              @removeTagFromMedia="removeTagFromMedia"
            />
          </div>
        </transition>
        <transition v-if="isMobile" name="slide-only">
          <div v-if="showCommentDetailsMobile" class="media-tag-details-mobile">
            <mobile-topbar text-key="COMMENTS_COMMENTS" @leftIconClicked="showCommentDetailsMobile = false" />
            <div class="p-2">
              <comments
                v-if="activeMedia.canViewComments"
                :can-comment="activeMedia.allowsComments"
                :parent-id="activeMedia.id"
                :parent-type="commentTypes.MEDIA"
                :comment-count="activeMedia.commentCount"
                :clear-all="clearAll"
                @commentAdded="updateMediaComment"
              />
            </div>
          </div>
        </transition>
      </div>
    </aula-modal>
    <aula-modal
      ref="deleteMediaModal"
      ok-text="BUTTON_YES"
      :disable-close="true"
      @cancelClicked="$refs.deleteMediaModal.hide(), $refs.mediaDetailModal.show()"
      @okClicked="deleteActiveMedia"
    >
      {{ 'GALLERY_DELETE_MEDIA_CONFIRM' | fromTextKey }}
      <p v-if="activeMedia.fromSearch">
        {{ 'GALLERY_DELETE_MEDIA_FROM_SEARCH' | fromTextKey }}
      </p>
    </aula-modal>
    <aula-modal
      ref="cancelCommentWhenUpdatingSlideModal"
      ok-text="YES"
      @okClicked="
        updateWritingComment(false);
        $refs.cancelCommentWhenUpdatingSlideModal.hide();
        clearAll = !clearAll;
      "
      @cancelClicked="goBackToComment()"
    >
      {{ 'COMMENTS_CONFIRM_CANCEL_COMMENT' | fromTextKey }}<br />
      {{ 'COMMENTS_CONFIRM_CANCEL_COMMENT_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="cancelCommentWhenCloseModal"
      ok-text="YES"
      @okClicked="
        updateWritingComment(false);
        nextRoute();
      "
      @cancelClicked="$refs.cancelCommentWhenCloseModal.hide()"
    >
      {{ 'COMMENTS_CONFIRM_CANCEL_COMMENT' | fromTextKey }}<br />
      {{ 'COMMENTS_CONFIRM_CANCEL_COMMENT_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="blockedCommunicationModal"
      :show-cancel="false"
      @okClicked="$refs.blockedCommunicationModal.hide()"
    >
      {{ 'CALENDAR_BLOCK_COMMUNICATION_BODY' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="reportContentModal"
      ok-text="REPORTED_CONTENT_SEND"
      header-text="REPORTED_CONTENT_HEADER"
      @okClicked="saveReportMedia()"
      @cancelClicked="
        $refs.reportContentModal.hide();
        $refs.cancelReportModal.show();
      "
    >
      {{ 'REPORTED_CONTENT_WRITE_A_REASON' | fromTextKey }}
      <b-form-textarea
        v-model="reportedContentReason"
        :placeholder="'REPORTED_CONTENT_FIELDS_REASON' | fromTextKey"
        @input="showWarningReasonLength = false"
      />
      <b-alert variant="danger" :show="showWarningReasonLength">
        {{ warningReasonLength | fromTextKey }}
      </b-alert>
      {{ 'REPORTED_CONTENT_NOTE' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="cancelReportModal"
      @okClicked="$refs.cancelReportModal.hide()"
      @cancelClicked="
        $refs.cancelReportModal.hide();
        $refs.reportContentModal.show();
      "
    >
      {{ 'REPORTED_CONTENT_CANCEL_REPORT_1' | fromTextKey }}<br />
      {{ 'REPORTED_CONTENT_CANCEL_REPORT_2' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { types } from '../../store/types/types';
import $ from 'jquery';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import MobileTopbar from '../../../shared/components/MobileTopbar';
import { docTypes } from '../../../shared/enums/docTypes';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { mediaTypes } from '../../../shared/enums/mediaTypes';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { moduleTypes } from '../../../shared/enums/moduleTypes';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { commentTypes } from '../../../shared/enums/commentTypes';
import Comments from '../../../shared/components/Comments';
import Vue from 'vue';
import AulaImg from '../../../shared/components/AulaImg';
import { providerKeyTypes } from '../../../shared/enums/providerKeyTypes';
import { conversionStatusTypes } from '../../../shared/enums/conversionStatusTypes';
import MediaTagHandling from '../../../shared/PageFragments/Media/MediaTagHandling';
import VideoThumbnail from '../../../shared/PageFragments/Media/VideoThumbnail.vue';
import SoundThumbnail from '../../../shared/PageFragments/Media/SoundThumbnail.vue';
import Button from '../../../shared/directives/button';
import { mediaFilterType } from '../../../shared/enums/mediaFilterType';
import Avatar from '../../../shared/components/Avatar.vue';
import ContactAvatar from '../contacts/ContactAvatar.vue';

export default {
  components: {
    ContactAvatar,
    Avatar,
    SoundThumbnail,
    VideoThumbnail,
    MediaTagHandling,
    AulaImg,
    Comments,
    MobileTopbar,
  },
  directives: {
    Button: Button,
  },
  inject: {
    onRemoveMediaFromAlbum: {
      from: providerKeyTypes.onRemoveMediaFromAlbum,
      default: () => () => null,
    },
  },
  props: {
    mediaId: { type: Number, default: null },
    album: { type: Array, default: () => [] },
    parent: { type: String, default: parentTypes.GALLERY },
    sortOn: { type: String },
    orderDirection: { type: String },
    filterBy: { type: String, default: mediaFilterType.ALL },
    filterInstProfileIds: { type: Array, default: () => [] },
  },
  data: function () {
    return {
      slide: -1,
      visible: true,
      thumbnailStartIndex: 0,
      thumbnailEndIndex: 8,
      docTypes: docTypes,
      portalRoles: portalRoles,
      mediaTypes: mediaTypes,
      parentTypes: parentTypes,
      moduleTypes: moduleTypes,
      commentTypes: commentTypes,
      showMediaDetails: false,
      showSharedWithDropdown: false,
      showTagDetailsMobile: false,
      showCommentDetailsMobile: false,
      clearAll: false,
      fullscreenMode: false,
      profileSelected: false,
      childSelected: [],
      albumMedia: [],
      reportedContentReason: '',
      nextRoute: '',
      prevSlide: '',
      showWarningReasonLength: false,
      warningReasonLength: null,
      mediaLimit: null,
      excludeTagIdDictionary: {},
      conversionStatus: conversionStatusTypes,
    };
  },
  computed: {
    ...mapGetters({
      activeGroup: types.GET_ACTIVE_GROUP,
      profile: types.GET_CURRENT_PROFILE,
      children: types.GET_CHILDREN,
      isMobile: types.GET_IS_MOBILE,
      activeAlbum: types.GET_ACTIVE_ALBUM,
      institutions: types.GET_INSTITUTIONS,
      hasPermission: types.HAS_PERMISSION,
      hasPermissionGroup: types.HAS_PERMISSION_ON_GROUP,
      writingComment: types.GET_WRITING_COMMENT,
      userGroups: types.GET_USER_GROUPS,
    }),
    mediaList() {
      if (this.albumMedia.length === 0) {
        return [this.activeMedia];
      }
      return this.albumMedia;
    },
    canHandleMedia() {
      return this.hasPermission(permissionEnum.HANDLE_MEDIA);
    },
    canEditTags() {
      return this.canHandleMedia;
    },
    filteredTags() {
      return this.activeMedia.tags?.filter(tag => this.excludeTagIdDictionary[tag.id] !== true) || [];
    },
    albumInstitutionCode() {
      if (this.activeAlbum && this.activeAlbum.creator) {
        return this.activeAlbum.creator.institutionCode;
      }
      return null;
    },
    tagsDetail() {
      if (this.otherTaggedUsersCount > 0) {
        return '+ ' + this.otherTaggedUsersCount + ' ' + Vue.filter('fromTextKey')('OTHERS');
      }

      return Vue.filter('fromTextKey')('TAG_MORE');
    },
    disableTagSearch() {
      return this.activeMedia.currentUserCanEditTags != null && !this.activeMedia.currentUserCanEditTags;
    },
    isGroupAdministrator() {
      if (this.activeAlbum != null && this.activeAlbum.sharedWithGroups != null) {
        for (const group of this.activeAlbum.sharedWithGroups) {
          if (this.hasPermissionGroup(permissionEnum.DELETE_POSTS_MEDIA_COMMENTS, group.id)) {
            return true;
          }
        }
      }
      return false;
    },
    isAdministrator() {
      return this.hasPermission(permissionEnum.ADMIN_MODULE);
    },
    activeMedia() {
      if (this.albumMedia[this.slide] != null) {
        return this.albumMedia[this.slide];
      } else {
        return {};
      }
    },
    otherTaggedUsersCount() {
      return this.activeMedia.tags.length - 1;
    },
    canDeleteMedia() {
      return (
        (this.institutions.filter(inst => inst.institutionProfileId == this.activeMedia.creator.id).length > 0 ||
          this.isGroupAdministrator ||
          this.isAdministrator ||
          this.activeMedia.currentUserCanDelete) &&
        (this.parent == parentTypes.GALLERY || this.parent == parentTypes.GROUP_GALLERY || this.activeMedia.fromSearch)
      );
    },
  },
  watch: {
    activeMedia() {
      if (this.activeMedia.mediaType === mediaTypes.VIDEO) {
        this.applyVideo(this.activeMedia);
      }
    },
    mediaLimit: {
      async handler(newVal, oldVal) {
        if (newVal === oldVal) {
          return;
        }
        if (newVal > oldVal && this.albumMedia.length < newVal) {
          const roomForMoreMedia = newVal - this.albumMedia.length;

          if (roomForMoreMedia > 0) {
            await this.getMoreMedia(this.albumMedia.length, roomForMoreMedia);
          }
        }
      },
    },
  },
  beforeDestroy() {
    this.albumMedia = null;
  },
  mounted() {
    this.initMedia();
    window.addEventListener('resize', this.setMediaLimit);
    this.$nextTick(() => {
      this.setMediaLimit();
    });
  },
  destroyed() {
    window.removeEventListener('resize', this.setMediaLimit);
  },
  methods: {
    ...mapActions({
      deleteMedia: types.DELETE_MEDIA,
      getAlbumMedia: types.ACTION_GET_ALBUM_DETAILS,
      updateMedia: types.UPDATE_ATTACHMENTS,
      reportMedia: types.REPORT_MEDIA,
      loadVideoFromSource: types.VC_LOAD_VIDEO_FROM_SOURCE,
    }),
    ...mapMutations({
      updateWritingComment: types.MUTATE_WRITING_COMMENT,
      removeMediaInStore: types.MUTATE_DELETE_MEDIA,
      setHasMediaChanged: types.MUTATE_HAS_MEDIA_CHANGED,
    }),
    showTagsDropdown() {
      if (!this.canHandleMedia) {
        return this.activeMedia.tags.length > 1;
      }
      return true;
    },
    updateMediaComment() {
      this.activeMedia.commentCount = this.activeMedia.commentCount == null ? 1 : this.activeMedia.commentCount + 1;
    },
    deleteActiveMedia() {
      this.deleteMedia({ mediaIds: [this.activeMedia.id] });
      this.$refs.deleteMediaModal.hide();
      this.visible = true;
      this.$refs.mediaDetailModal.show();
      this.removeMediaFromAlbumAndGoToNextSlide();
    },
    openReportMediaModal() {
      if (this.profile.communicationBlock) {
        this.$refs.blockedCommunicationModal.show();
      } else {
        this.reportedContentReason = '';
        this.resetCommentForm();
        this.$refs.reportContentModal.show();
      }
    },
    resetCommentForm() {
      this.showWarningReasonLength = false;
      this.warningReasonLength = null;
    },
    saveReportMedia() {
      if (this.reportedContentReason.length < 10 || this.reportedContentReason.length > 255) {
        this.showWarningReasonLength = true;
        this.warningReasonLength =
          this.reportedContentReason.length < 10
            ? 'REPORTED_CONTENT_REASON_LENGTH_WARNING_TOO_SHORT'
            : 'REPORTED_CONTENT_REASON_LENGTH_WARNING_TOO_LONG';
      } else {
        this.resetCommentForm();
        this.reportMedia({
          id: this.activeMedia.id,
          reportReason: this.reportedContentReason,
        }).then(() => {
          this.$refs.reportContentModal.hide();
          this.reportedContentReason = '';
          this.removeMediaInStore({ mediaIds: [this.activeMedia.id] });
          this.removeMediaFromAlbumAndGoToNextSlide();
          this.setHasMediaChanged(true);

          if (this.albumMedia.length === 0) {
            this.closeMediaDetail();
          }
        });
      }
    },
    async showNextThumbnails() {
      if (this.thumbnailEndIndex + this.mediaLimit > this.albumMedia.length) {
        await this.getMoreMedia(this.albumMedia.length, this.mediaLimit - (this.albumMedia.length % this.mediaLimit));
        this.setThumbnailIndexes(this.albumMedia.length - this.mediaLimit);
      } else {
        if (this.thumbnailStartIndex + this.mediaLimit < this.albumMedia.length) {
          this.setThumbnailIndexes(this.thumbnailEndIndex);
        }
      }
    },
    showPrevThumbnails() {
      if (this.thumbnailStartIndex - this.mediaLimit <= 0) {
        this.setThumbnailIndexes(0);
      } else {
        this.setThumbnailIndexes(this.thumbnailStartIndex - this.mediaLimit);
      }
    },
    goBackToComment() {
      this.$refs.cancelCommentWhenUpdatingSlideModal.hide();
      this.updateWritingComment(false);
      this.nextRoute(this.prevSlide);
      setTimeout(() => {
        this.updateWritingComment(true);
      }, 50);
    },
    updateSlide(slide) {
      if (this.writingComment) {
        if (this.slide !== slide) {
          this.prevSlide = this.slide;
        }
        this.nextRoute = this.confirmUpdateSlide;
        this.$refs.cancelCommentWhenUpdatingSlideModal.show();
      } else {
        this.prevSlide = this.slide;
        this.slide = slide;
        const mediaId = this.albumMedia[slide].id;
        const params = this.$route.params;
        params.mediaId = mediaId;
        this.$router.push({ name: this.$route.name, params: params });
        if (slide === 0) {
          this.setThumbnailIndexes(0);
        }
      }
    },
    async confirmUpdateSlide(slide) {
      if (this.parent !== parentTypes.USERDATA) {
        $('.video,.audio').each(function () {
          this.pause();
        });
        if (slide >= this.thumbnailEndIndex) {
          if (this.thumbnailEndIndex + this.mediaLimit > this.albumMedia.length) {
            await this.getMoreMedia(
              this.albumMedia.length,
              this.mediaLimit - (this.albumMedia.length % this.mediaLimit)
            );
          }
          if (this.thumbnailEndIndex < this.albumMedia.length - 1) {
            this.setThumbnailIndexes(slide);
          }
        } else {
          if (slide <= this.thumbnailStartIndex) {
            this.showPrevThumbnails();
          }
        }
        this.slide = slide;
        if (this.mediaId != null) {
          return;
        }
        const mediaId = this.albumMedia[slide].id;
        const params = this.$route.params;
        params.mediaId = mediaId;
        this.$router.push({ name: this.$route.name, params: params });
      }
    },
    applyVideo(media) {
      this.loadVideoFromSource(media.file).then(objectUrl => {
        if (objectUrl == null) {
          return;
        }
        const videoElement = document.getElementById('video' + media.id);
        if (videoElement.src !== objectUrl) {
          videoElement.src = objectUrl;
        }
      });
    },
    downloadMedia() {
      window.location = this.activeMedia.file.url;
    },
    removeMediaFromAlbumAndGoToNextSlide() {
      this.$emit('mediaDelete', this.activeMedia.id);
      if (this.albumMedia.length === 1) {
        this.closeMediaDetail();
        return;
      }

      const mediaIndex = this.slide;
      const activeMedia = this.albumMedia[mediaIndex];
      this.albumMedia = this.albumMedia.filter(media => media.id != this.activeMedia.id);
      let nextMedia;
      this.onRemoveMediaFromAlbum(activeMedia);

      if (this.albumMedia[this.slide]) {
        nextMedia = this.albumMedia[this.slide];
      } else {
        nextMedia = this.albumMedia[0];
        this.slide = 0;
      }

      if (this.$route.name === 'myMediaDetails') {
        this.$router.push({
          name: 'myMediaDetails',
          params: { mediaId: nextMedia.id },
        });
      } else if (this.$route.name === 'group-media-details') {
        this.$router.push({
          name: 'group-album-details',
          params: {
            groupId: this.$route.params.groupId,
            albumId: this.activeAlbum.id,
          },
        });
      } else {
        this.$router.push({
          name: 'mediaDetails',
          params: { albumId: this.activeAlbum.id, mediaId: nextMedia.id },
        });
      }
    },
    cancelTagging() {
      this.$refs.mediaTagHandling.hide();
    },
    hideTagDetailsMobile() {
      this.showTagDetailsMobile = false;
    },
    addTagsToMedia(tags) {
      let tagIds = this.activeMedia.tags.map(tag => tag.id);
      if (tags.length > 0) {
        const newUserIds = tags.map(tag => tag.id);
        tagIds = uniq(tagIds.concat(newUserIds));
        const media = {
          media: [
            {
              id: this.activeMedia.id,
              tags: tagIds,
              description: this.activeMedia.description,
              title: this.activeMedia.title,
            },
          ],
        };
        if (this.activeMedia.tags.filter(tag => tag.id == tags.id).length == 0) {
          this.updateMedia(media).then(() => {
            this.activeMedia.tags = uniqBy(this.activeMedia.tags.concat(tags), tag => tag.id);
            this.$emit('tagsUpdated', this.activeMedia.tags);
          });
        }
      }
    },
    removeTagFromMedia(mediaTag) {
      this.excludeTagIdDictionary = {
        ...this.excludeTagIdDictionary,
        [mediaTag.id]: true,
      };
      const media = {
        media: [
          {
            id: this.activeMedia.id,
            tags: this.filteredTags.map(tag => tag.id),
            description: this.activeMedia.description,
            title: this.activeMedia.title,
          },
        ],
      };
      this.updateMedia(media)
        .then(() => {
          const tagIndex = this.activeMedia.tags.findIndex(tag => tag.id === mediaTag.id);
          this.activeMedia.tags.splice(tagIndex, 1);
          this.$emit('tagsUpdated', this.activeMedia.tags);
          this.hasUserPermissionToViewMediaAfterRemovingTag(mediaTag);
        })
        .finally(() => {
          delete this.excludeTagIdDictionary[mediaTag.id];
        });
    },
    hasUserPermissionToViewMediaAfterRemovingTag(mediaTag) {
      if (
        !this.activeAlbum &&
        this.$route.name === 'myMediaDetails' &&
        this.institutions.find(i => i.institutionProfileId === mediaTag.id) != null &&
        this.userGroups.filter(g => this.activeMedia.sharedGroupIds.includes(g.id)).length === 0
      ) {
        this.removeMediaInStore({ mediaIds: [this.activeMedia.id] });
        this.removeMediaFromAlbumAndGoToNextSlide();
      }
    },
    closeMediaDetail() {
      if (this.writingComment) {
        this.$refs.mediaDetailModal.show();
        this.nextRoute = this.confirmCloseMediaDetails;
        this.$refs.cancelCommentWhenCloseModal.show();
      } else {
        this.confirmCloseMediaDetails();
      }
    },
    closeMediaDetailInGallery() {
      if (this.activeAlbum != null) {
        if (this.albumMedia.length != this.album.length) {
          this.getMoreMedia(0, 11);
        }
        this.$router.push('/galleri/' + this.activeAlbum.id);
      } else {
        this.$router.push({ name: 'myMedias' });
      }
    },
    closeMediaDetailInCalendar() {
      switch (this.$route.name) {
        case 'viewMediaEvent':
          this.$router.push({
            name: 'viewEvent',
            params: { eventId: this.$route.params.eventId },
          });
          break;
        case 'viewMediaMeetingEvent':
          this.$router.push({
            name: 'viewMeetingEvent',
            params: { eventId: this.$route.params.eventId },
          });
          break;
        case 'group-calendar-view-media-event':
          this.$router.push({
            name: 'group-calendar-view-event',
            params: {
              groupId: this.$route.params.groupId,
              eventId: this.$route.params.eventId,
            },
          });
          break;
        case 'group-calendar-view-media-meeting-event':
          this.$router.push({
            name: 'group-calendar-view-meeting-event',
            params: {
              groupId: this.$route.params.groupId,
              eventId: this.$route.params.eventId,
            },
          });
          break;
        default:
          this.$router.push({ name: 'calendar' });
      }
    },
    confirmCloseMediaDetails() {
      this.$emit('onClosed');
      if (this.mediaId) {
        return;
      }

      switch (this.parent) {
        case parentTypes.GROUP_GALLERY:
          this.$router.push({
            name: 'group-album-details',
            params: {
              groupId: this.activeGroup.id,
              albumId: this.activeAlbum.id,
            },
          });
          break;
        case parentTypes.GROUP_CALENDAR:
          this.$router.push({
            name: 'group-calendar',
            params: { groupId: this.activeGroup.id },
          });
          break;
        case parentTypes.NOTICE_BOARDS:
          this.$router.push({ name: 'notice-board' });
          break;
        case parentTypes.GALLERY:
          this.closeMediaDetailInGallery();
          break;
        case parentTypes.CALENDAR:
          this.closeMediaDetailInCalendar();
          break;
        case parentTypes.MESSAGES:
          this.$router.push({
            name: 'uniqueThread',
            params: { id: this.$route.params.id },
          });
          break;
        case parentTypes.ADMINISTRATION:
          this.$emit('closeMediaDetail');
          break;
        default:
          this.$router.go(-1);
      }
    },
    setMediaLimit() {
      const thumbnailWidth = this.$refs.mediaThumbnails.clientWidth;
      const imageWidth = 104;
      this.mediaLimit = Math.ceil(thumbnailWidth / imageWidth);
      if (this.slide > this.mediaLimit) {
        this.thumbnailStartIndex = this.slide;
        this.thumbnailEndIndex = this.slide + this.mediaLimit;
      }
    },
    initMedia() {
      let index = 0;
      const mediaId = this.mediaId || this.$route.params.mediaId;
      this.albumMedia = this.album.filter(media => media.conversionStatus !== conversionStatusTypes.PROCESSING);
      const albumMediaIndex = this.albumMedia.findIndex(m => m.id == mediaId);
      if (mediaId && albumMediaIndex > -1) {
        index = albumMediaIndex;
      }
      this.slide = index;
      this.prevSlide = this.slide;
    },
    async getMoreMedia(index, limit) {
      if (this.parent != parentTypes.GALLERY || !this.$parent.moreMediasExist) {
        return;
      }
      const params = {
        albumId: this.activeAlbum?.id,
        userSpecificAlbum: this.activeAlbum?.id == null,
        filterBy: this.filterBy,
        filterInstProfileIds: this.filterInstProfileIds,
        index,
        limit,
      };
      if (this.sortOn && this.orderDirection) {
        params.sortOn = this.sortOn;
        params.orderDirection = this.orderDirection;
      }
      await this.getAlbumMedia(params);
    },
    setThumbnailIndexes(thumbnailStartIndex) {
      this.thumbnailStartIndex = thumbnailStartIndex;
      this.thumbnailEndIndex =
        thumbnailStartIndex + Math.min(this.albumMedia.length - thumbnailStartIndex, this.mediaLimit) - 1;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
.media-shared-with {
  /deep/ .dropdown-menu {
    margin-top: 36px;
    padding-top: 0;
    div {
      float: right;
    }
  }
}
.print_tag {
  &:after {
    content: ', ';
  }
  &:last-child:after {
    content: '';
  }
}
.carousel.fromSearch /deep/ {
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
}
.media-creator {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
  width: 100%;
  @include breakpoint-lg() {
    margin-top: -20px;
  }
  .user {
    width: 50px;
  }
  .media-creator-infor {
    flex-grow: 1;
  }
  .media-creator-name {
    font-size: 16px;
    font-weight: bold;
  }
  .media-date {
    font-size: 14px;
    color: var(--color-grey);
  }
}

.media-info {
  overflow-wrap: break-word;
}

.failed-conversion-container {
  --questionmark-icon-background-color: var(--color-grey-base);
  border-top: 1px solid $color-grey-darker;
  border-bottom: 1px solid $color-grey-darker;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.small-thumbnail {
  &:not([aria-selected='true']) {
    opacity: 0.6;
  }
  > * {
    --width: 100px;
    --height: 100px;
  }
}
</style>
